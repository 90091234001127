// @ts-ignore
import React        from "react"
// import {HeaderSlider} from '../lib/headerslider'
import {FindMd}     from '../lib/FindMd'
import {ThisLayout} from '../components/thisLayout'

const Index = ({data}) => {
  // let thissitemetadata = thisSiteMetaData()
  let md_data          = FindMd({file_name: 'index.md'})
  // let slides: { filename: string, texts: string[] | string[][] }[] = [
  //   {
  //     // filename: "22596935_m.jpg", texts: [
  //     // filename: "5006221_m.jpg", texts: [
  //     filename: "3661904_m.jpg", texts: [
  //       ["介護でお金がなくてお困りの方へ"],
  //       // ["新しい生き方を模索してください", "!text-red-500"],
  //     ],
  //   },
  //   {
  //     // filename: "5006231_m.jpg", texts: [
  //     filename: "4716076_m.jpg", texts: [
  //       "古い持ち家・マンションの",
  //       "処分でお困りの方へ",
  //     ],
  //   },
  //   {
  //     filename: "2782079_m.jpg", texts: [
  //       "生活保護を受けたいとお考えの方へ",
  //     ],
  //   },
  // ]
  return <>
    <ThisLayout md_data={md_data}>
      {/*<div className="w-full">*/}
      {/*  /!*<HeaderSlider slides={thissitemetadata.slides}/>*!/*/}
      {/*  <HeaderSlider slides={slides}/>*/}
      {/*</div>*/}
    </ThisLayout>
  </>
}

export default Index
